(function($) {
  "use strict"; // Start of use strict

  // Vide - Video Background Settings
  $('body').vide({
    mp4: "/files/"+ comingsoon_picture_movie +".mp4",
    poster: "/files/"+ comingsoon_picture_movie +".jpg"
  }, {
    posterType: 'jpg'
  });

})(jQuery); // End of use strict
